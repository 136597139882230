@import "bootstrap/scss/bootstrap";
@import "font-awesome/css/font-awesome.css";
@import "ngx-toastr/toastr";

/* You can add global styles to this file, and also import other style files */
.alert-danger {
    color: #dc3545;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.alert {
    position: relative;
    padding: .25rem 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    margin-top: 0.1rem;
}

.modal-sm {
    max-width: 250px;
}


.table {
    margin: 0px;
}

.table {
  font-family: 'Segoe UI Semilight', 'Open Sans', Verda1, Arial, Helvetica, sans-serif; }

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  font-weight: normal;
  font-size: 14px; 
}


label.field-name, div.field-name {
    font-weight: 450;
}

.form-control:focus {
    border-color: #00aec7;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 174, 199, 1);
  }


.btn-outline-primary {
    color: #00aec7;
    background-color: transparent;
    background-image: none;
    border-color: #00aec7;
  }

.btn-outline-primary:hover {
    color: #fff;
    background-color: #00aec7;
    border-color: #00aec7;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 174, 199, 1);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #00aec7;
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #00aec7;
    border-color: #00aec7;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 174, 199, 1);
}

.btn-link {
    color: #00aec7;
}

.btn-link:hover {
    color: #17a2b8;
}


.starkey-email {
    background-image: url("../assets/email.png");
    background-size: 15px 12px;
    display: inline-block;
    width: 15px;
    height: 12px;
}
  
.starkey-email:hover {
    opacity: 0.5;
    filter: alpha(opacity=40);
}




.starkey-circle-down {
    background-image: url("../assets/circle-down.svg");
    background-size: 15px 12px;
    display: inline-block;
    width: 15px;
    height: 12px;
}

// .starkey-circle-down:hover {
//     opacity: 0.5;
//     filter: alpha(opacity=40);
// }

.starkey-circle-right {
    background-image: url("../assets/circle-right.svg");
    background-size: 15px 12px;
    display: inline-block;
    width: 15px;
    height: 12px;
}

// .starkey-circle-right:hover {
//     opacity: 0.5;
//     filter: alpha(opacity=40);
// }

/* Specify styling for popover contents */

.popover.customClass,
.popover.customClass .popover-content {
    border-color:#00AEC7;
}


.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: #00AEC7;
}

popover-container {
    min-width: 50px;
}


/*Override styles for bs dropdown */
.dropup .dropdown-toggle,
.dropdown .dropdown-toggle {
    text-align: left;
}

.dropup .dropdown-toggle::after,
.dropdown .dropdown-toggle::after {
    display:none;
}

.dropup .menu-container,
.dropdown .menu-container {
    width: 100%;
    color:#495057;
    background-color:#fff;
    border-color:#17a2b8;
    outline:0;
    box-shadow:0 0 5px 0rem rgb(23, 162, 184);
    padding: 10px;
    max-height: 200px;
    overflow-y: auto;
}

.dropup .dropdown-item,
.dropdown .dropdown-item {
    display: block;
    background-color: #fff;
    border: none;
}

.dropup .dropdown-item:hover ,
.dropdown .dropdown-item:hover {
    background-color: rgb(30, 144, 255);
    color: #fff;
}